import TVKEYS from "../../tv/tvkeys";
import AbstractView from "../AbstractView";
import { deactivateApp } from "../../components/broadcast-object";

class Dama extends AbstractView {
    constructor(params) {
        super(params);
        this.setTitle('Dama');
        this.setActiveHeader('header-games');
        this.playing = true;
        this.handled = false;
    }

    async getHtml() {
        return ` 
        <div class="dama-wrapper">
            <div class='button' id='backButton' href="/games" data-link="" tabindex="-1">
                <div class='button-blue'></div>
                <span class='button-text-back'>Geri</span>
            </div>            
            <h2 class="title-text" id="title_text">Dama</h2>
            <div id="scoreboard" class="scoreboard">
                <div>Oyuncu yenen taşlar: <span id="playerXWins">0</span></div>
                <div>Bilgisayar yenen taşlar: <span id="playerOWins">0</span></div>
            </div>
            <div class="dama-container">
                ${Array.from({ length: 64 }, (_, i) => `<div class='dama-cell' id='cell${i}'></div>`).join('')}
            </div>
            <div id="rulesTable" class="rules">
                <div class="rules-content">
                    <h2>Dama Kuralları</h2>
                    <ul>
                        <li>Oyuna beyaz taşları kullanan oyuncu başlar.</li>
                        <li>Bir taş, karşı tarafın en son sırasına ulaştığında, dama olur.</li>
                        <li>Bir taş, yatay veya dikey ileri yönde bir kare ilerleyebilir. Damaya çıkan taş, yatay veya dikey ileri ve geri yönde birden fazla kare ilerleyebilir.</li>
                        <li>Eğer rakibin taşını alabilen hamleler varsa, rakibin taşını almak zorunludur. Bir taş, rakip taşın üzerinden atlayarak onu alabilir. Bir taş alındığında, aynı taşla alınabilecek başka taşlar bulunuyorsa taş birden çok hamle yapabilir.</li>
                        <li>Rakip oyuncunun tüm taşları alındığında oyun sona erer, taşı kalmayan oyuncu kaybetmiş olur. Her iki taraf da 15 taş aldığında oyun berabere biter.</li>
                    </ul>
                    <div class='button' id='rulesButton'>
                        <div class='button-yellow'></div>
                        <span class='button-text'>Kapatmak için sarıya basın</span>
                    </div>
                </div>
            </div>
            <div id='notification' class='notification'></div>
            <div class='button-container'>
                <div class='button' id='rulesButton'>
                    <div class='button-yellow'></div>
                    <span class='button-text'>Dama Kuralları</span>
                </div>
                <div class='button' id='startNewButton'>
                    <div class='button-green'></div>
                    <span class='button-text'>Yeni Oyun</span>
                </div>
            </div>
        </div>
        `;
    }

    async navigate() {
    }

    async afterRender() {
        var currentPlayer = 'W';
        var startingBoard = ['', '', '', '', '', '', '', '',
            'B', 'B', 'B', 'B', 'B', 'B', 'B', 'B',
            'B', 'B', 'B', 'B', 'B', 'B', 'B', 'B',
            '', '', '', '', '', '', '', '',
            '', '', '', '', '', '', '', '',
            'W', 'W', 'W', 'W', 'W', 'W', 'W', 'W',
            'W', 'W', 'W', 'W', 'W', 'W', 'W', 'W',
            '', '', '', '', '', '', '', '',
        ];
        var currentSelection = 0;
        var selectedPiece = null;
        var blackScore = 0;
        var whiteScore = 0;
        var captureIndex = null;

        const game = () => {
            function handleEnterKey() {
                var capturingMovesAvailable = hasCapturingMoves(currentPlayer);
                showNotification(capturingMovesAvailable ? 'Taşı almak zorunlu' : '');

                if (selectedPiece === null) {
                    selectPiece(capturingMovesAvailable);
                } else if (selectedPiece === currentSelection) {
                    clearHighlights();
                    selectedPiece = null;
                } else {
                    moveSelectedPiece();
                }
            }

            function selectPiece(capturingMovesAvailable) {
                if (startingBoard[currentSelection] === currentPlayer || startingBoard[currentSelection] === currentPlayer + 'K') {
                    var possibleMoves = getPossibleMoves(currentSelection);
                    var hasCapture = false;
                    for (var i = 0; i < possibleMoves.length; i++) {
                        if (possibleMoves[i].capture !== null) {
                            hasCapture = true;
                            break;
                        }
                    }

                    if (!capturingMovesAvailable || hasCapture) {
                        selectedPiece = currentSelection;
                        highlightPossibleMoves(selectedPiece);
                    }
                }
            }

            function moveSelectedPiece() {
                if (document.getElementById('cell' + currentSelection).classList.contains('highlight')) {
                    movePiece(selectedPiece, currentSelection);
                    clearHighlights();
                    if (captureIndex !== null && hasCapturingMovesAt(currentSelection)) {
                        selectedPiece = currentSelection;
                        highlightPossibleMoves(selectedPiece);
                    } else {
                        selectedPiece = null;
                        if (!checkEndGame()) {
                            switchTurn();
                        }
                    }
                }
            }

            function showNotification(message) {
                document.getElementById('notification').innerText = message;
            }

            function showRules() {
                var modal = document.getElementById('rulesTable');
                if (modal.style.display === 'block') {
                    modal.style.display = 'none';
                } else {
                    modal.style.display = 'block';
                }
            }

            function hasCapturingMovesAt(index) {
                var moves = getPossibleMoves(index);
                for (var i = 0; i < moves.length; i++) {
                    if (moves[i].capture !== null) {
                        return true;
                    }
                }
                return false;
            }

            function moveSelection(change) {
                unhighlightSquare(currentSelection);
                currentSelection = (currentSelection + change + 64) % 64;
                highlightSquare(currentSelection);
            }


            function highlightSquare(index) {
                var cell = document.getElementById('cell' + index);
                if (cell) {
                    cell.classList.add('highlight-square');
                }
            }

            function unhighlightSquare(index) {
                var cell = document.getElementById('cell' + index);
                if (cell) {
                    cell.classList.remove('highlight-square');
                }
            }

            function highlightPossibleMoves(index) {
                var possibleMoves = getPossibleMoves(index);
                for (var i = 0; i < possibleMoves.length; i++) {
                    var move = possibleMoves[i];
                    var cell = document.getElementById('cell' + move.to);
                    if (cell) {
                        cell.classList.add('highlight');
                    }
                }
            }

            function getPossibleMoves(index) {
                var moves = [];
                var piece = startingBoard[index];
                var directions = [];

                if (piece === 'W') {
                    directions = [-8, -1, 1];
                } else if (piece === 'B') {
                    directions = [8, -1, 1];
                } else if (piece === 'WK' || piece === 'BK') {
                    directions = [-8, 8, -1, 1];
                }

                for (var i = 0; i < directions.length; i++) {
                    var direction = directions[i];
                    var newIndex = index + direction;

                    while (isValidMove(index, newIndex, piece)) {
                        moves.push({ to: newIndex, capture: null });
                        newIndex += direction;
                        if (newIndex < 0 || newIndex >= 64) break;
                    }
                    var captureIndex = newIndex + direction;

                    while (isValidCapture(index, newIndex, captureIndex, piece)) {
                        moves.push({ to: captureIndex, capture: newIndex });
                        captureIndex += direction;
                        if (captureIndex < 0 || captureIndex >= 64) break;
                    }
                }

                var capturingMoves = [];
                for (var j = 0; j < moves.length; j++) {
                    if (moves[j].capture !== null) {
                        capturingMoves.push(moves[j]);
                    }
                }

                return capturingMoves.length > 0 ? capturingMoves : moves;
            }

            function hasCapturingMoves(player) {
                for (var i = 0; i < 64; i++) {
                    if (startingBoard[i] === player || startingBoard[i] === player + 'K') {
                        var moves = getPossibleMoves(i);
                        for (var j = 0; j < moves.length; j++) {
                            if (moves[j].capture !== null) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            }

            function isValidMove(fromIndex, toIndex, piece) {
                if (toIndex < 0 || toIndex >= 64) return false;
                if (startingBoard[toIndex] !== '') return false;

                var fromRow = Math.floor(fromIndex / 8);
                var toRow = Math.floor(toIndex / 8);
                var fromCol = fromIndex % 8;
                var toCol = toIndex % 8;

                if (piece === 'W' || piece === 'B') {
                    return Math.abs(fromRow - toRow) + Math.abs(fromCol - toCol) === 1;
                } else if (piece === 'WK' || piece === 'BK') {
                    var step;
                    if (fromRow === toRow) {
                        // Horizontal move
                        step = (toCol > fromCol) ? 1 : -1;
                        for (var col = fromCol + step; col !== toCol; col += step) {
                            if (startingBoard[fromRow * 8 + col] !== '') return false;
                        }
                    } else if (fromCol === toCol) {
                        // Vertical move
                        step = (toRow > fromRow) ? 8 : -8;
                        for (var row = fromRow + step / 8; row !== toRow; row += step / 8) {
                            if (startingBoard[row * 8 + fromCol] !== '') return false;
                        }
                    } else {
                        return false;
                    }
                }

                return true;
            }


            function isValidCapture(fromIndex, overIndex, toIndex, piece) {
                if (toIndex < 0 || toIndex >= 64) return false;
                if (startingBoard[toIndex] !== '') return false;
                if (overIndex < 0 || overIndex >= 64) return false;
                if (startingBoard[overIndex] === '' || startingBoard[overIndex][0] === piece[0]) return false;

                var fromRow = Math.floor(fromIndex / 8);
                var toRow = Math.floor(toIndex / 8);
                var fromCol = fromIndex % 8;
                var toCol = toIndex % 8;

                if (piece === 'W' || piece === 'B') {
                    return Math.abs(fromRow - toRow) + Math.abs(fromCol - toCol) === 2;
                } else if (piece === 'WK' || piece === 'BK') {
                    var step;
                    var opponentFound;
                    if (fromRow === toRow) {
                        step = (toCol > fromCol) ? 1 : -1;
                        opponentFound = false;
                        for (var col = fromCol + step; col !== toCol; col += step) {
                            if (startingBoard[fromRow * 8 + col] !== '') {
                                if (col === overIndex % 8 && !opponentFound) {
                                    opponentFound = true;
                                } else {
                                    return false;
                                }
                            }
                        }
                        return opponentFound;
                    } else if (fromCol === toCol) {
                        step = (toRow > fromRow) ? 8 : -8;
                        opponentFound = false;
                        for (var row = fromRow + step / 8; row !== toRow; row += step / 8) {
                            if (startingBoard[row * 8 + fromCol] !== '') {
                                if (row === Math.floor(overIndex / 8) && !opponentFound) {
                                    opponentFound = true;
                                } else {
                                    return false;
                                }
                            }
                        }
                        return opponentFound;
                    } else {
                        return false;
                    }
                }

                return true;
            }

            function clearHighlights() {
                var highlightedCells = document.querySelectorAll('.highlight');
                for (var i = 0; i < highlightedCells.length; i++) {
                    highlightedCells[i].classList.remove('highlight');
                }
            }

            function checkEndGame() {
                if (whiteScore === 16) {
                    document.getElementById('notification').innerText = 'Beyaz Kazandı';
                    return true;
                } else if (blackScore === 16) {
                    document.getElementById('notification').innerText = 'Siyah Kazandı';
                    return true;
                } else if (whiteScore === 15 && blackScore === 15) {
                    document.getElementById('notification').innerText = 'Berabere';
                    return true;
                }
                return false;
            }

            function movePiece(fromIndex, toIndex) {
                captureIndex = null;
                var possibleMoves = getPossibleMoves(fromIndex);
                for (var i = 0; i < possibleMoves.length; i++) {
                    if (possibleMoves[i].to === toIndex) {
                        captureIndex = possibleMoves[i].capture;
                    }
                }

                startingBoard[toIndex] = startingBoard[fromIndex];
                startingBoard[fromIndex] = '';
                if (captureIndex !== null) {
                    if (startingBoard[captureIndex] === 'B' || startingBoard[captureIndex] === 'BK') {
                        whiteScore++;
                        document.getElementById('playerXWins').innerText = whiteScore;
                    } else if (startingBoard[captureIndex] === 'W' || startingBoard[captureIndex] === 'WK') {
                        blackScore++;
                        document.getElementById('playerOWins').innerText = blackScore;
                    }
                    startingBoard[captureIndex] = '';
                }
                renderBoard();
                if (toIndex < 8 && startingBoard[toIndex] === 'W') {
                    startingBoard[toIndex] = 'WK';
                }
                if (toIndex >= 56 && startingBoard[toIndex] === 'B') {
                    startingBoard[toIndex] = 'BK';
                }
                document.getElementById('notification').innerText = '';
            }

            function switchTurn() {
                currentPlayer = (currentPlayer === 'W') ? 'B' : 'W';
                if (currentPlayer === 'B') {
                    setTimeout(computerMove, 500);
                }
            }


            function computerMove() {
                var possibleMoves = [];
                for (var i = 0; i < 64; i++) {
                    if (startingBoard[i] === 'B' || startingBoard[i] === 'BK') {
                        var moves = getPossibleMoves(i);
                        for (var j = 0; j < moves.length; j++) {
                            possibleMoves.push({ from: i, to: moves[j].to, capture: moves[j].capture });
                        }
                    }
                }

                if (possibleMoves.length > 0) {
                    var capturingMoves = [];
                    for (var k = 0; k < possibleMoves.length; k++) {
                        if (possibleMoves[k].capture !== null) {
                            capturingMoves.push(possibleMoves[k]);
                        }
                    }

                    var move;
                    if (capturingMoves.length > 0) {
                        move = capturingMoves[Math.floor(Math.random() * capturingMoves.length)];
                    } else {
                        var safeMoves = [];
                        for (var l = 0; l < possibleMoves.length; l++) {
                            var simBoard = startingBoard.slice();
                            simBoard[possibleMoves[l].to] = simBoard[possibleMoves[l].from];
                            simBoard[possibleMoves[l].from] = '';
                            if (!hasCapturingMovesAt(possibleMoves[l].to, simBoard, 'W')) {
                                safeMoves.push(possibleMoves[l]);
                            }
                        }

                        move = safeMoves.length > 0 ? safeMoves[Math.floor(Math.random() * safeMoves.length)] : possibleMoves[Math.floor(Math.random() * possibleMoves.length)];
                    }

                    movePiece(move.from, move.to);
                    if (!checkEndGame()) {
                        switchTurn();
                    }
                }
            }

            function renderBoard() {
                for (var i = 0; i < 64; i++) {
                    var cell = document.getElementById('cell' + i);
                    if (cell) {
                        cell.innerHTML = '';

                        if (startingBoard[i] === 'B') {
                            cell.innerHTML = '<span class="piece black"></span>';
                        } else if (startingBoard[i] === 'W') {
                            cell.innerHTML = '<span class="piece white"></span>';
                        } else if (startingBoard[i] === 'BK') {
                            cell.innerHTML = '<span class="piece black king"></span>';
                        } else if (startingBoard[i] === 'WK') {
                            cell.innerHTML = '<span class="piece white king"></span>';
                        }
                    }
                }
            }

            function startNewGame() {
                unhighlightSquare(currentSelection);
                currentPlayer = 'W';
                currentSelection = 0;
                startingBoard = ['', '', '', '', '', '', '', '',
                    'B', 'B', 'B', 'B', 'B', 'B', 'B', 'B',
                    'B', 'B', 'B', 'B', 'B', 'B', 'B', 'B',
                    '', '', '', '', '', '', '', '',
                    '', '', '', '', '', '', '', '',
                    'W', 'W', 'W', 'W', 'W', 'W', 'W', 'W',
                    'W', 'W', 'W', 'W', 'W', 'W', 'W', 'W',
                    '', '', '', '', '', '', '', '',
                ];
                renderBoard();
                highlightSquare(currentSelection);
                clearHighlights();
                blackScore = 0;
                whiteScore = 0;
                document.getElementById('playerXWins').innerText = whiteScore;
                document.getElementById('playerOWins').innerText = blackScore;
                document.getElementById('notification').innerText = '';
            }

            renderBoard();
            highlightSquare(currentSelection);
            return {
                moveSelection, startNewGame, showRules, handleEnterKey
            };


        };

        let navigationHandler = null;

        function createNavigationHandler(
            { moveSelection, startNewGame, showRules, handleEnterKey }
        ) {
            return function (e) {
                switch (e.keyCode) {
                    case TVKEYS.VK_LEFT:
                        moveSelection(-1);
                        break;
                    case TVKEYS.VK_RIGHT:
                        moveSelection(1);
                        break;
                    case TVKEYS.VK_UP:
                        moveSelection(-8);
                        break;
                    case TVKEYS.VK_DOWN:
                        moveSelection(8);
                        break;
                    case TVKEYS.VK_GREEN:
                        startNewGame();
                        break;
                    case TVKEYS.VK_YELLOW:
                        showRules();
                        break;
                    case TVKEYS.VK_RED:
                        deactivateApp();
                        break;
                    case TVKEYS.VK_ENTER:
                        handleEnterKey();
                        break;
                    case TVKEYS.VK_BLUE:
                        document.removeEventListener('keydown', navigationHandler);
                        document.getElementById("backButton").click();
                        break;
                    default:
                        break;
                }
            };
        }

        function init() {
            const gameFunctions = game();
            let navigationHandler = createNavigationHandler(gameFunctions);
            document.addEventListener('keydown', navigationHandler);
        }

        init();


    }

}

export default Dama;
