import AbstractView from './AbstractView.js';
import TVKEYS from '../tv/tvkeys.js';
import axios from 'axios';
import combineSlideHelpers from '../helpers/slideHelper.js';
import { deactivateApp } from '../components/broadcast-object.js';
class TvShows extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Dizi ve Programlar');
    this.setActiveHeader("header-tvshows");
    this.tvShowsLive = null;
    this.tvShowsArchive = null;
    this.tvProgramLive = null;
  }

  async getDataLive() {
    const response = await axios.get('https://hbbappapi.adekstra.com/api/content/live/CT01/');
    const data = response.data;
    return data;
  }
  async getDataProgram() {
    const response = await axios.get('https://hbbappapi.adekstra.com/api/content/live/CT03/');
    const data = response.data;
    return data;
  }
  async getDataArchive() {
    const response = await axios.get('https://hbbappapi.adekstra.com/api/content/archive/CT01/');
    const data = response.data;
    return data;
  }
  async getDataFromServer() {
    this.tvShowsLive = await this.getDataLive();
    this.tvShowsArchive = await this.getDataArchive();
    this.tvProgramLive = await this.getDataProgram();
  }

  renderLiveSeries() {
    return this.tvShowsLive.map((item) => (
      `
      <div class="program" href="/program-detail/${item.id}" data-link="" tabindex="-1" >
        <img src="${item.media_files.image_files[0].file}" alt="program" tabindex="-1" />
        <div class="program-title">
        ${item.name}
        </div>
      </div>
      `
    )).join('');
  }

  renderArchivePrograms() {
    return this.tvShowsArchive.map((item) => (
      `
      <div class="program" href="/program-detail/${item.id}" data-link="" tabindex="-1" >
        <img src="${item.media_files.image_files[0].file}" alt="program" tabindex="-1" />
        <div class="program-title">
        ${item.name}
        </div>
      </div>
      `
    )).join('');
  }

  renderLiveProgram() {
    return this.tvProgramLive.map((item) => (
      `
      <div class="program" href="/program-detail/${item.id}" data-link="" tabindex="-1" >
        <img src="${item.media_files.image_files[0].file}" alt="program" tabindex="-1" />
        <div class="program-title">
        ${item.name}
        </div>
      </div>
      `
    )).join('');
  }



  async getHtml() {
    await this.getDataFromServer();
    return `
  
  <div class="left-content">
    <div class="tvshows-container">
      <div class="tvshows-title">GÜNCEL DİZİLER</div>
      <div class="tvshows-list actual-series">
      ${this.renderLiveSeries()}
      </div>

      <div class="tvshows-title">GÜNCEL PROGRAMLAR</div>
      <div class="tvshows-list actual-programs">
      ${this.renderLiveProgram()}
      </div>

      <div class="tvshows-title">ARŞİV DİZİLER</div>
      <div class="tvshows-list archive-series">
      ${this.renderArchivePrograms()}
      </div>
    </div>

    
  </div>
    `;
  }

  async navigate() {
    const containers = document.querySelectorAll('.header-wrapper, .actual-series, .actual-programs, .archive-series');
    const containersArray = [];
    for (let i = 0; i < containers.length; i++) {
      containersArray.push(containers[i]);
    }
    let currentContainerIndex = 0;
    let currentBlockIndex = 2;

    let navigationHandler = null;
    document.addEventListener('keydown', navigationHandler = createNavigationHandler());

    function createNavigationHandler() {
      let blocks = [];
      const children = containers[currentContainerIndex].children;
      for (let i = 0; i < children.length; i++) {
        blocks.push(children[i]);
      }

      return function (e) {
        const target = document.querySelector('.selected');

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                document.removeEventListener('keydown', navigationHandler);
              }
              document.querySelector('.selected').click();
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            if (currentBlockIndex > 0) {
              currentBlockIndex--;
              if (currentContainerIndex > 0) {
                combineSlideHelpers.changeTvShowsX(currentBlockIndex, containers[currentContainerIndex]);
              }
            }
            break;
          case TVKEYS.VK_RIGHT:
            if (currentBlockIndex < blocks.length - 1) {
              currentBlockIndex++;
              if (currentContainerIndex > 0) {
                combineSlideHelpers.changeTvShowsX(currentBlockIndex, containers[currentContainerIndex]);
              }
            }
            break;
          case TVKEYS.VK_UP:
            if (currentContainerIndex > 0) {
              currentContainerIndex--;
              currentBlockIndex = 0;
              combineSlideHelpers.slideTvShowsY(currentBlockIndex, currentContainerIndex);
            }
            break;
          case TVKEYS.VK_DOWN:
            if (currentContainerIndex < containers.length - 1) {
              currentContainerIndex++;
              currentBlockIndex = 0;
              combineSlideHelpers.slideTvShowsY(currentBlockIndex, currentContainerIndex);
            }
            break;
          case TVKEYS.VK_RED:
            deactivateApp();
            break;
          default:
            break;
        }

        containersArray.forEach((container, index) => {
          if (index === currentContainerIndex) {
            container.classList.add('active-container');
            blocks = [].slice.call(containers[index].children);
            const selectedElementList = document.querySelectorAll('.selected');
            for (let i = 0; i < selectedElementList.length; i++) {
              selectedElementList[i].classList.remove('selected');
            }
          } else {
            container.classList.remove('active-container');
          }
        });
        blocks.forEach((block, index) => {
          if (index === currentBlockIndex) {
            block.classList.add('selected');
          } else {
            block.classList.remove('selected');
          }
        });
      };
    }
  }


}

export default TvShows;
