import AbstractView from './AbstractView.js';

import TVKEYS from '../tv/tvkeys';
import axios from 'axios';
import { deactivateApp } from '../components/broadcast-object.js';
import combineSlideHelpers from '../helpers/slideHelper.js';
import combineHelpers from '../helpers/helper.js';

class Search extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Program Ara');
    this.setActiveHeader("header-search");
    this.programs = null;
    this.navigationHandler = null;
    this.currentContainerIndex = 0;
    this.currentBlockIndex = 0;
  }

  renderKeyboard() {
    return `
    <div class="keyboard-wrapper">
      <div class="key-line key-line-1">
        <div class="letter letter-delete">
          <i class="fa-solid fa-delete-left"><span class="delete-text">delete</span></i>
        </div>
        <div class="letter letter-space">
          <i class="fa-solid fa-arrows-left-right-to-line"><span class="space-text">space</span></i>
        </div>
      </div>
      <div class="key-line key-line-2">
        <div class="letter letter-1">a</div>
        <div class="letter letter-2">b</div>
        <div class="letter letter-3">c</div>
        <div class="letter letter-4">ç</div>
        <div class="letter letter-5">d</div>
        <div class="letter letter-6">e</div>
      </div>
      <div class="key-line key-line-3">
        <div class="letter letter-7">f</div>
        <div class="letter letter-8">g</div>
        <div class="letter letter-9">ğ</div>
        <div class="letter letter-10">h</div>
        <div class="letter letter-11">ı</div>
        <div class="letter letter-12">i</div>
      </div>
      <div class="key-line key-line-4">
        <div class="letter letter-13">j</div>
        <div class="letter letter-14">k</div>
        <div class="letter letter-15">l</div>
        <div class="letter letter-16">m</div>
        <div class="letter letter-17">n</div>
        <div class="letter letter-18">o</div>
      </div>
      <div class="key-line key-line-5">
        <div class="letter letter-19">ö</div>
        <div class="letter letter-20">p</div>
        <div class="letter letter-21">q</div>
        <div class="letter letter-22">r</div>
        <div class="letter letter-23">s</div>
        <div class="letter letter-24">ş</div>
      </div>
      <div class="key-line key-line-6">
        <div class="letter letter-25">t</div>
        <div class="letter letter-26">u</div>
        <div class="letter letter-27">ü</div>
        <div class="letter letter-28">v</div>
        <div class="letter letter-29">w</div>
        <div class="letter letter-30">x</div>
      </div>
      <div class="key-line key-line-7">
        <div class="letter letter-31">y</div>
        <div class="letter letter-32">z</div>
        <div class="letter letter-33">1</div>
        <div class="letter letter-34">2</div>
        <div class="letter letter-35">3</div>
        <div class="letter letter-36">4</div>
      </div>
      <div class="key-line key-line-8">
        <div class="letter letter-37">5</div>
        <div class="letter letter-38">6</div>
        <div class="letter letter-39">7</div>
        <div class="letter letter-40">8</div>
        <div class="letter letter-41">9</div>
        <div class="letter letter-42">0</div>
      </div>
    </div>
      `;
  }

  setKeyboard() {
    const rightContent = document.querySelector(".banner-product-wrapper");
    rightContent.innerHTML = this.renderKeyboard();
  }

  renderProgram() {
    return this.programs.map((item) => (
      `
        <div class="program" href="/program-detail/${item.id}" data-link="" tabindex="-1" >
          <img src="${item.media_files.image_files[0].file}" alt="program" tabindex="-1" />
          <div class="program-title">
            ${item.name}
          </div>
        </div>
      `
    )).join('');
  }

  async getPrograms(text) {
    const response = await axios.get(`https://hbbappapi.adekstra.com/api/content/search/?text=${text}`);
    this.programs = response.data;
    if (response.data.length === 0) {
      const messageElem = document.querySelector(".search-message");
      messageElem.innerHTML = "Aramanıza uygun sonuç bulunamadı.";
    }
    else {
      const messageElem = document.querySelector(".search-message");
      messageElem.innerHTML = "";
    }
    const programList = document.querySelector(".program-clips-search");
    programList.innerHTML = this.renderProgram();
    document.removeEventListener('keydown', this.navigationHandler);
    this.navigate();
  }

  addSearchContent(val) {
    const searchContent = document.querySelector(".search-content");
    const trimmedVal = val.replace(/\s/g, '');
    if (trimmedVal == "delete") {
      const text = searchContent.textContent.slice(0, -1);
      searchContent.textContent = text;
      this.getPrograms(text);
    }
    else if (trimmedVal == "space") {
      const text = searchContent.textContent + " ";
      searchContent.textContent = text;
      this.getPrograms(text);
    }
    else {
      const text = searchContent.textContent + val;
      searchContent.textContent = text;
      this.getPrograms(text);
    }
  }

  async getHtml() {
    this.setKeyboard();
    return `
      <div class="left-content">
        <div class="search-bar">
          <i class="fa-solid fa-magnifying-glass search-icon"></i>
          <div class="search-content"></div>
        </div>

        <div class="program-clips-search">
        </div>

        <div class="search-message">
        </div>

      </div>
  
    `;
  }

  async navigate() {
    let containers;
    if (this.programs && this.programs.length) {
      containers = document.querySelectorAll('.header-wrapper, .key-line-1, .key-line-2, .key-line-3, .key-line-4, .key-line-5, .key-line-6, .key-line-7, .key-line-8, .program-clips-search');
    }
    else {
      containers = document.querySelectorAll('.header-wrapper, .key-line-1, .key-line-2, .key-line-3, .key-line-4, .key-line-5, .key-line-6, .key-line-7, .key-line-8');
    }
    const containersArray = [];
    for (let i = 0; i < containers.length; i++) {
      containersArray.push(containers[i]);
    }

    const that = this;
    document.addEventListener('keydown', this.navigationHandler = createNavigationHandler());

    function createNavigationHandler() {
      let blocks = [];
      const children = containers[that.currentContainerIndex].children;
      for (let i = 0; i < children.length; i++) {
        blocks.push(children[i]);
      }

      return function (e) {
        const target = document.querySelector('.selected');
        let upCondition;
        let downCondition;
        if (that.programs && that.programs.length) {
          upCondition = 3 < that.currentContainerIndex && that.currentContainerIndex < 10;
          downCondition = 2 < that.currentContainerIndex && that.currentContainerIndex < 9;
        }
        else {
          upCondition = 2 < that.currentContainerIndex && that.currentContainerIndex < 9;
          downCondition = 1 < that.currentContainerIndex && that.currentContainerIndex < 8;
        }

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                document.removeEventListener('keydown', that.navigationHandler);
                combineHelpers.setAdvertisements();
              }
              document.querySelector('.selected').click();
              if (target.classList.contains("letter")) {
                const textContent = target.textContent;
                that.addSearchContent(textContent);
              }
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            if (that.currentContainerIndex > 1 && that.currentContainerIndex < 10 && that.programs && that.programs.length && that.currentBlockIndex == 0) { //check condition
              that.currentContainerIndex = 1;
              that.currentBlockIndex = 0;
            }
            else if (that.currentBlockIndex > 0) {
              that.currentBlockIndex--;
            }
            break;
          case TVKEYS.VK_RIGHT:
            if(that.programs && that.programs.length && that.currentContainerIndex === 1 && that.currentBlockIndex % 3 == 2){
              that.currentContainerIndex = 3;
              that.currentBlockIndex = 0;

            }
            else if (that.currentBlockIndex < blocks.length - 1) {
              that.currentBlockIndex++;
            }
            break;
          case TVKEYS.VK_UP:
            if (upCondition) {
              that.currentContainerIndex--;
            }
            else if (that.programs && that.programs.length && that.currentContainerIndex === 1 && that.currentBlockIndex > 2) {
              that.currentBlockIndex -= 3;
            }
            else if (that.currentContainerIndex > 0) {
              that.currentContainerIndex--;
              that.currentBlockIndex = 0;
            }
            combineSlideHelpers.slideSearchY(that.currentBlockIndex, that.currentContainerIndex);
            break;
          case TVKEYS.VK_DOWN:
            if (downCondition) {
              that.currentContainerIndex++;
            }
            else if (that.programs && that.programs.length && that.currentContainerIndex === 1 && that.currentBlockIndex < (blocks.length - 3)) {
              that.currentBlockIndex += 3;
            }
            else if (that.currentContainerIndex < containers.length - 1) {
              that.currentContainerIndex++;
              that.currentBlockIndex = 0;
            }
            combineSlideHelpers.slideSearchY(that.currentBlockIndex, that.currentContainerIndex);
            break;
          case TVKEYS.VK_RED:
            deactivateApp();
            break;
          default:
            break;
        }

        containersArray.forEach((container, index) => {
          if (index === that.currentContainerIndex) {
            container.classList.add('active-container');
            blocks = [].slice.call(containers[index].children);
            const selectedElementList = document.querySelectorAll('.selected');
            for (let i = 0; i < selectedElementList.length; i++) {
              selectedElementList[i].classList.remove('selected');
            }
          } else {
            container.classList.remove('active-container');
          }
        });
        blocks.forEach((block, index) => {
          if (index === that.currentBlockIndex) {
            block.classList.add('selected');
          } else {
            block.classList.remove('selected');
          }
        });
      };
    }
  }


}

export default Search;
