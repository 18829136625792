import AbstractView from './AbstractView.js';
import TVKEYS from '../tv/tvkeys';
import axios from 'axios';
import combineSlideHelpers from '../helpers/slideHelper.js';
import { deactivateApp } from '../components/broadcast-object.js';

class Home extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Ana Sayfa');
    this.setActiveHeader("header-home");
    this.latestEpisodes = null;
    this.bannerData = null;
  }


  async getLatestEpisodes() {
    const response = await axios.get('https://hbbappapi.adekstra.com/api/content_detail/latest/episode');
    this.latestEpisodes = response.data;
  }
  async getBannerProgram() {
    const response = await axios.get(`https://hbbappapi.adekstra.com/api/display_case/`);
    this.bannerData = response.data[0];
  }

  renderProgram() {
    const defaultURL = "errorMedia";
    const defaultUrlImage = "https://dummyimage.com/240x144/000000/ffffff.png";

    return this.latestEpisodes.map((item, index) => {
      const mediaFile = item.media_files.video_files[0]?.file || defaultURL;
      const mediaImage = item.media_files?.image_files[0]?.file
        || item.content_season?.media_files?.image_files[0]?.file
        || defaultUrlImage;

      return `
        <div class="program" href="/media/${item.id}?name=${item.title}&amp;media=${mediaFile}&amp;index=${index}" data-link="" tabindex="-1" >
          <img src="${mediaImage}" alt="program" />
          <div class="program-title">
            <div class="mb-5"><b>${item.title}</b></div>
            <div>${item.episode_num}. Bölüm</div>
          </div>
        </div>
      `;
    }).join('');
  }

  async getHtml() {
    await this.getLatestEpisodes();
    await this.getBannerProgram();
    const bannerFile = this.bannerData.content_schedule.content_detail.content.media_files.video_files[0]?.file || "errorMedia";
    return `
  <div class="left-content">
    <div class="banner-wrapper">
      <img class="banner-program" src="${this.bannerData.media_files.image_files[0].file}"  href="/media/${this.bannerData.id}?name=${this.bannerData.title}&amp;media=${bannerFile}&amp;index=${0}" data-link="" tabindex="-1"  alt="camdakikiz" />
    </div>
    <div class="program-series-title">YEPYENİ BÖLÜMLER</div>
    <div class="program-series">
      ${this.renderProgram()}

    </div>
  </div>
  
    `;
  }

  async navigate() {
    const containers = document.querySelectorAll('.header-wrapper, .banner-wrapper, .program-series');
    const containersArray = [];
    for (let i = 0; i < containers.length; i++) {
      containersArray.push(containers[i]);
    }
    let currentContainerIndex = 0;
    let currentBlockIndex = 0;

    let navigationHandler = null;
    let keySequence = [];
    const targetSequence = [TVKEYS.VK_GREEN, TVKEYS.VK_YELLOW, TVKEYS.VK_YELLOW, TVKEYS.VK_GREEN];
    
    document.addEventListener('keydown', navigationHandler = createNavigationHandler());

    function createNavigationHandler() {
      let blocks = [];
      const children = containers[currentContainerIndex].children;
      for (let i = 0; i < children.length; i++) {
        blocks.push(children[i]);
      }

      return function (e) {
        const target = document.querySelector('.selected');

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                document.removeEventListener('keydown', navigationHandler);
              }
              document.querySelector('.selected').click();
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            if (currentBlockIndex > 0) {
              currentBlockIndex--;
              combineSlideHelpers.slideHomeX(currentBlockIndex, currentContainerIndex);
            }
            break;
          case TVKEYS.VK_RIGHT:
            if (currentBlockIndex < blocks.length - 1) {
              currentBlockIndex++;
              combineSlideHelpers.slideHomeX(currentBlockIndex, currentContainerIndex);
            }
            break;
          case TVKEYS.VK_UP:
            if (currentContainerIndex > 0) {
              currentContainerIndex--;
              currentBlockIndex = 0;
            }
            break;
          case TVKEYS.VK_DOWN:
            if (currentContainerIndex < containers.length - 1) {
              currentContainerIndex++;
              currentBlockIndex = 0;
            }
            break;
          case TVKEYS.VK_RED:
            deactivateApp();
            break;
          case TVKEYS.VK_GREEN:
          case TVKEYS.VK_YELLOW:
            keySequence.push(e.keyCode);
            if (keySequence.length > targetSequence.length) {
              keySequence.shift();
            }
            if (JSON.stringify(keySequence) === JSON.stringify(targetSequence)) {
              const consoleArea = document.getElementById('console-area');
              if (consoleArea.style.display === 'none' || !consoleArea.style.display) {
                consoleArea.style.display = 'block';
              } else {
                consoleArea.style.display = 'none';
              }
              keySequence = [];
            }
            break;
          default:
            break;
        }
  
        containersArray.forEach((container, index) => {
          if (index === currentContainerIndex) {
            container.classList.add('active-container');
            blocks = [].slice.call(containers[index].children);
            const selectedElementList = document.querySelectorAll('.selected');
            for (let i = 0; i < selectedElementList.length; i++) {
              selectedElementList[i].classList.remove('selected');
            }
          } else {
            container.classList.remove('active-container');
          }
        });
        blocks.forEach((block, index) => {
          if (index === currentBlockIndex) {
            block.classList.add('selected');
          } else {
            block.classList.remove('selected');
          }
        });
      };
    }
  }
  

}

export default Home;
