import axios from 'axios';
import moment from 'moment';
import { deactivateApp } from '../components/broadcast-object.js';
import combineSlideHelpers from '../helpers/slideHelper.js';
import TVKEYS from '../tv/tvkeys.js';
import AbstractView from './AbstractView.js';

class NewsDetail extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Haberler');
    this.setActiveHeader("header-news");
    this.detailId = params.id;
    this.newsData = null;
  }

  async getNewsDetail() {
    const response = await axios.get(`https://hbbappapi.adekstra.com/api/news/${this.detailId}`);
    this.newsData = response.data;
  }

  async getHtml() {
    await this.getNewsDetail();
    const newsData = this.newsData;
    const imageUrl = newsData.media_files?.image_files[0]?.file ?? "https://dummyimage.com/400x240/000000/ffffff.png";
    const date = moment(newsData.release_date).format('DD.MM.YYYY');

    function escapeHtml(unsafe) {
      return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    }

    return `
      <div class="left-content">
        <div class="news-detail-header">
          <img class="news-img" src="${imageUrl}" alt="news" />
          <div class="title">
            <div class="news-title-top">
              <div class="news-date">${date}</div>
              <div class="news-back-wrapper">
                <div class="news-back" href="/news" data-link="" tabindex="-1" >
                  <i class="fa-solid fa-arrow-left"></i>Geri
                </div>
              </div>
              <div class="title-desc">${escapeHtml(newsData.title)}</div>
            </div>
          </div>
        </div>
  
        <div class="news-desc-container">
          <div class="news-description">
            ${escapeHtml(newsData.description)}
          </div>
        </div>
  
      </div>
    `;
  }

  async navigate() {
    const containers = document.querySelectorAll('.header-wrapper, .news-back-wrapper, .news-desc-container');
    const containersArray = [];
    for (let i = 0; i < containers.length; i++) {
      containersArray.push(containers[i]);
    }
    let currentContainerIndex = 0;
    let currentBlockIndex = 3;

    let navigationHandler = null;
    document.addEventListener('keydown', navigationHandler = createNavigationHandler());

    function createNavigationHandler() {
      let blocks = [];
      const children = containers[currentContainerIndex].children;
      for (let i = 0; i < children.length; i++) {
        blocks.push(children[i]);
      }

      return function (e) {
        const target = document.querySelector('.selected');

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                document.removeEventListener('keydown', navigationHandler);
              }
              document.querySelector('.selected').click();
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            if (currentBlockIndex > 0) {
              currentBlockIndex--;
            }
            break;
          case TVKEYS.VK_RIGHT:
            if (currentBlockIndex < blocks.length - 1) {
              currentBlockIndex++;
            }
            break;
          case TVKEYS.VK_UP:
            if (currentContainerIndex > 0) {
              currentContainerIndex--;
              currentBlockIndex = 0;
              combineSlideHelpers.formatNewsDetail();
            }
            break;
          case TVKEYS.VK_DOWN:
            if (currentContainerIndex < containers.length - 1) {
              currentContainerIndex++;
              currentBlockIndex = 0;
            }
            combineSlideHelpers.slideNewsDetailY(currentBlockIndex, currentContainerIndex);
            break;
          case TVKEYS.VK_RED:
            deactivateApp();
            break;
          default:
            break;
        }

        containersArray.forEach((container, index) => {
          if (index === currentContainerIndex) {
            container.classList.add('active-container');
            blocks = [].slice.call(containers[index].children);
            const selectedElementList = document.querySelectorAll('.selected');
            for (let i = 0; i < selectedElementList.length; i++) {
              selectedElementList[i].classList.remove('selected');
            }
          } else {
            container.classList.remove('active-container');
          }
        });
        blocks.forEach((block, index) => {
          if (index === currentBlockIndex) {
            block.classList.add('selected');
          } else {
            block.classList.remove('selected');
          }
        });
      };
    }
  }


}

export default NewsDetail;
