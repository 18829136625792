import AbstractView from './AbstractView.js';
import axios from 'axios';
import moment from 'moment';
import TVKEYS from '../tv/tvkeys.js';
import combineSlideHelpers from '../helpers/slideHelper.js';
import { deactivateApp } from '../components/broadcast-object.js';
class Streaming extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Yayın Akışı');
    this.setActiveHeader("header-streaming");
    this.day = new Date();
    this.date = new Date();
    this.selectedDayItems = [];
    this.dayOfWeek = this.day.getDay();
    this.streamingItems = null;
    this.selectedDayItems = null;
    this.days = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
    this.months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];

    this.selectedDayIndex = null;
  }

  async getSchedule() {
    const response = await axios.get('https://hbbappapi.adekstra.com/api/content_schedule/weekly/');
    const data = response.data.weekly_schedule;
    return data;
  }

  async getScheduleForDay(day) {
    this.selectedDayIndex = day;
    this.streamingItems = await this.getSchedule();
    this.selectedDayItems = this.streamingItems[day].content_schedule;
  }

  prevDay() {
    const prevDate = new Date(this.date);
    prevDate.setDate(this.date.getDate() - 1);
    if (prevDate.getDay() !== 0) {
      this.date = prevDate;
      this.updateDate();
      this.changeSelectedDayItems(prevDate.getDay() - 1);
    }
  }

  nextDay() {
    const nextDate = new Date(this.date);
    nextDate.setDate(this.date.getDate() + 1);
    if (nextDate.getDay() !== 1) {
      this.date = nextDate;
      this.updateDate();
      if (nextDate.getDay() == 0) {
        this.changeSelectedDayItems(6);
      } else {
        this.changeSelectedDayItems(nextDate.getDay() - 1);
      }
    }
  }

  formatDate(date) {
    const dayOfWeek = this.days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = this.months[date.getMonth()];
    return ` ${dayOfMonth} ${month} <b> ${dayOfWeek} </b>`;
  }

  updateDate() {
    const dateElement = document.querySelector('.streaming-date');
    if (dateElement) {
      dateElement.innerHTML = this.formatDate(this.date);
    }
  }

  changeSelectedDayItems(day) {
    this.selectedDayIndex = day;
    this.selectedDayItems = this.streamingItems[day].content_schedule;
    this.streamingList = document.querySelector('.streaming-list');
    this.streamingList.innerHTML = this.renderProgram();
  }




  renderProgram() {
    return this.selectedDayItems.map((item, index) => {
      const primaryImage = item.detail_files?.image_files[0];
        const fallbackImage = item.content_files?.image_files[0];
        
        const imageUrl = primaryImage?.file || fallbackImage?.file || "https://dummyimage.com/240x144/000000/ffffff.png";


      const programLink = item.content_files ? `/streaming-detail/${index}?day-index=${this.selectedDayIndex}&amp;program-index=${index}` : "/streaming";

      return (
        `
        <div class="program" href="${programLink}" data-link="" tabindex="-1" >
          <img src="${imageUrl}" alt="program" />
          <div class="program-title">
            ${item.title}
          </div>
          <div class="program-date">
          ${moment(item.start_time, "HH:mm:ss").format("HH:mm")}
          </div>
        </div>
        `
      );
    }).join('');
  }

  async getHtml() {
    const dayOfWeek = this.dayOfWeek == 0 ? 6 : this.dayOfWeek - 1;
    await this.getScheduleForDay(dayOfWeek);
    return `
      <div class="left-content">
        <div class="streaming-list-header">
          <div class="streaming-list-title">Yayın Akışı: <span class="streaming-date"></span></div>
          <div class="streaming-buttons">
            <div class="prev-day" tabindex="-1"><i class="fa-solid fa-angle-left"></i>Önceki Gün</div>
            <div class="next-day" tabindex="-1">Sonraki Gün<i class="fa-solid fa-angle-right"></i></div>
          </div>
        </div>
        <div class="streaming-list">
        ${this.renderProgram()}
        </div>
      </div>
    `;
  }

  async navigate() {
    const containers = document.querySelectorAll('.header-wrapper, .streaming-buttons, .streaming-list');
    const containersArray = [];
    for (let i = 0; i < containers.length; i++) {
      containersArray.push(containers[i]);
    }
    let currentContainerIndex = 0;
    let currentBlockIndex = 1;

    let navigationHandler = null;
    document.addEventListener('keydown', navigationHandler = createNavigationHandler());

    function createNavigationHandler() {
      let blocks = [];
      const children = containers[currentContainerIndex].children;
      for (let i = 0; i < children.length; i++) {
        blocks.push(children[i]);
      }

      return function (e) {
        const target = document.querySelector('.selected');

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                document.removeEventListener('keydown', navigationHandler);
              }
              document.querySelector('.selected').click();
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            if (currentBlockIndex > 0) {
              currentBlockIndex--;
              combineSlideHelpers.slideStreamingY(currentBlockIndex, currentContainerIndex);
            }
            break;
          case TVKEYS.VK_RIGHT:
            if (currentBlockIndex < blocks.length - 1) {
              currentBlockIndex++;
              combineSlideHelpers.slideStreamingY(currentBlockIndex, currentContainerIndex);
            }
            break;
          case TVKEYS.VK_UP:
            if (currentContainerIndex === 2 && currentBlockIndex > 2) {
              currentBlockIndex -= 3;
            }
            else if (currentContainerIndex > 0) {
              currentContainerIndex--;
              currentBlockIndex = 0;
            }
            combineSlideHelpers.slideStreamingY(currentBlockIndex, currentContainerIndex);
            break;
          case TVKEYS.VK_DOWN:
            if (currentContainerIndex === 2 && currentBlockIndex < blocks.length - 3) {
              currentBlockIndex += 3;
            }
            else if (currentContainerIndex < containers.length - 1) {
              currentContainerIndex++;
              currentBlockIndex = 0;
            }
            combineSlideHelpers.slideStreamingY(currentBlockIndex, currentContainerIndex);

            break;
          case TVKEYS.VK_RED:
            deactivateApp();
            break;
          default:
            break;
        }

        containersArray.forEach((container, index) => {
          if (index === currentContainerIndex) {
            container.classList.add('active-container');
            blocks = [].slice.call(containers[index].children);
            const selectedElementList = document.querySelectorAll('.selected');
            for (let i = 0; i < selectedElementList.length; i++) {
              selectedElementList[i].classList.remove('selected');
            }
          } else {
            container.classList.remove('active-container');
          }
        });
        blocks.forEach((block, index) => {
          if (index === currentBlockIndex) {
            block.classList.add('selected');
          } else {
            block.classList.remove('selected');
          }
        });
      };
    }
  }

  async afterRender() {
    this.updateDate();
    document.querySelector('.prev-day').addEventListener('click', (e) => {
      this.prevDay(e);
    });
    document.querySelector('.next-day').addEventListener('click', (e) => {
      this.nextDay(e);
    });
  }

}

export default Streaming;
